<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            width="150"
            :src="require('@/assets/images/logo/logo.png')"
          />
        </b-link>

        <b-card-title class="mb-1">
          Agora falta pouco! 😀
        </b-card-title>
        <b-card-text class="mb-2">
          Faça a verificação do seu acesso e comece a monitorar.
        </b-card-text>

        <validation-observer
          ref="validacao"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              label-for="loginInput"
              label="E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="loginInput"
                  v-model="form.login"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="pessoa@empresa.com.br"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Senha"
              label-for="senhaInput"
            >
              <senha-input
                id="senhaInput"
                v-model="form.senha"
                required
                :validacao-nome="'Senha'"
              />
            </b-form-group>

            <b-form-group
              label="Confirmar senha"
              label-for="confirmarSenha"
            >
              <senha-input
                id="confirmarSenha"
                v-model="form.confirmarSenha"
                required
                :validacao-nome="'Confirmar senha'"
              />
              <small class="text-danger">{{ loginError }}</small>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="confirmarAcesso()"
            >
              Confirmar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-cycle */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import {
  clearLoggedIn, updateAbility,
} from '@/auth/utils'
import api from './shared/services/api'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SenhaInput: () => import('@pilar/components/senha-input/SenhaInput.vue'),
  },
  data() {
    return {
      email,
      required,
      form: {
        token: this.$route.query.token,
        login: this.$route.query.email,
        senha: '',
        confirmarSenha: '',
      },
      loginError: '',
    }
  },
  created() {
    clearLoggedIn()
  },
  methods: {
    confirmarAcesso() {
      this.loginError = ''
      this.$refs.validacao.validate()
        .then(() => {
          api.confirmarAcesso(this.form)
            .then(payload => {
              useJwt.setUserData(payload.data.userData)
              useJwt.setToken(payload.data.accessToken)

              updateAbility(this.$ability)
              this.$router.push({ name: 'bemvindo' })
            })
            .catch(error => {
              this.loginError = error.response.data.message
              this.$refs.validacao.setErrors({ Password: error.response.data.message })
            })
        })
    },
  },
}
</script>
