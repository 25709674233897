import axios from '@axios'

const PREFIX = '/acessos'

export default {

  confirmarAcesso(confirmacao) {
    return axios.post(`${PREFIX}/confirmar-acesso`, confirmacao)
  },
}
